import { useEffect, useState } from 'react'
import { urlApi } from './config'
import Lobbies from './components/Lobbies/index'
import useFetch from './hooks/useFetch'
import Menu from './components/Menu'
import Messages from './components/Messages'
import Notifications from './components/Notifications'
import { useAuth0 } from '@auth0/auth0-react'
import StatisticsDemand from './components/Statistics/StatisticsDemand'
import StatisticsSupply from './components/Statistics/StatisticsSupply'
import DepartmentTable from './components/DepartmentTable'
import UserSearch from './components/UserSearch'
import { Drawer, ThemeProvider } from '@mui/material'
import DriverPayments from './components/DriverPayments'
import ChatBroadcast from './components/ChatBroadcast'
import NewsPopup from './components/NewsPopup'
import LoadingIndicator from './common/LoadingIndicator'
import Coupons from './components/Coupons'
import Companions from './components/Companions'
import GTM from './components/GTM'
import { muiInputTheme } from './utils'

const App = () => {
  const { locale } = getLocale()
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  const [selectedLobby, setSelectedLobby] = useState({})
  const [messages, setMessages] = useState(false)
  const params = new URLSearchParams(window.location.search)
  const defaultNavigation = params.get('navigation')
  const [navigator, navigate] = useState(defaultNavigation ? defaultNavigation : 'Lobbies')
  const [openDrawer, setOpenDrawer] = useState(false)

  const isMobile = window.innerWidth < 768

  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('logout')) return logout()

  if (isLoading) {
    return (
      <div className='h-screen w-screen flex items-center justify-center'>
        <LoadingIndicator size={26} />
      </div>
    )
  } else if (!isLoading && !isAuthenticated) {
    loginWithRedirect({
      appState: window.location.href
    })
    return (
      <div className='w-full h-screen flex justify-center items-center'>Inciando sesión...</div>
    )
  }

  return (
    <ThemeProvider theme={muiInputTheme}>
      {isMobile ? (
        <>
          <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <div className='flex flex-col gap-2 p-6 items-center'>
              <StatisticsButton
                navigator={navigator}
                navigate={navigate}
                component='StatisticsDemand'
                name='Demanda'
              />
              <StatisticsButton
                navigator={navigator}
                navigate={navigate}
                component='StatisticsSupply'
                name='Supply'
              />
              <SectionButton
                name={'Buscar usuario'}
                navigator={navigator}
                navigate={navigate}
                component={'UserSearch'}
              />
              <SectionButton
                name={'Pagos pendientes'}
                navigator={navigator}
                navigate={navigate}
                component={'DriverPayments'}
              />
              <SectionButton
                name={'Chat Broadcast'}
                navigator={navigator}
                navigate={navigate}
                component={'ChatBroadcast'}
              />
              <SectionButton
                name={'Popup'}
                navigator={navigator}
                navigate={navigate}
                component={'NewsPopup'}
              />
              <SectionButton
                name={'Cupones'}
                navigator={navigator}
                navigate={navigate}
                component={'Coupons'}
              />
              <div
                className='bg-gray-400 w-32 h-10 rounded-lg
                flex justify-center items-center cursor-pointer'
                onClick={() => logout({ returnTo: window.origin })}
              >
                Log out
              </div>
            </div>
          </Drawer>
          <Menu isMobile setMessages={setMessages}>
            <button
              onClick={() => setOpenDrawer(true)}
              data-collapse-toggle='navbar-default'
              type='button'
              className='inline-flex items-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-gray-200 ml-auto'
              aria-controls='navbar-default'
              aria-expanded='false'
            >
              <svg
                className='w-8 h-8'
                aria-hidden='true'
                fill='#d65944'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          </Menu>
          <div className='w-full mt-3 flex flex-col'>
            {/* {
                navigator == 'Lobbies' ?

                  :

              } */}
            {locale == 'ar' ? (
              <GTM locale={locale} hidden={navigator != 'Lobbies'} />
            ) : (
              <div hidden={navigator != 'Lobbies'}>
                <Lobbies selectedLobby={selectedLobby} setSelectedLobby={setSelectedLobby} />
              </div>
            )}
            <div hidden={navigator != 'Notifications'}></div>
            {(navigator == 'StatisticsDemand' || navigator == 'Departments') && (
              <StatisticsDemand navigate={navigate} navigator={navigator} />
            )}
            {navigator == 'StatisticsSupply' && (
              <StatisticsSupply navigate={navigate} navigator={navigator} />
            )}
            {navigator == 'Departments' && <DepartmentTable navigate={navigate} />}
            {navigator == 'UserSearch' && (
              <UserSearch navigate={navigate} defaultId={params.get('user_id')} />
            )}
            {navigator == 'DriverPayments' && (
              <DriverPayments navigate={navigate} locale={locale} />
            )}
            {navigator == 'ChatBroadcast' && <ChatBroadcast navigate={navigate} />}
            {navigator == 'NewsPopup' && <NewsPopup navigate={navigate} />}
            {navigator == 'Coupons' && <Coupons navigate={navigate} />}
          </div>
        </>
      ) : (
        <>
          <Menu isMobile={isMobile} setMessages={setMessages}>
            <StatisticsButton
              navigator={navigator}
              navigate={navigate}
              component='StatisticsDemand'
              name='Demanda'
            />
            <StatisticsButton
              navigator={navigator}
              navigate={navigate}
              component='StatisticsSupply'
              name='Supply'
            />
            <SectionButton
              name={'Buscar usuario'}
              navigator={navigator}
              navigate={navigate}
              component={'UserSearch'}
            />
            <SectionButton
              name={'Pagos pendientes'}
              navigator={navigator}
              navigate={navigate}
              component={'DriverPayments'}
            />
            <SectionButton
              name={'Chat Broadcast'}
              navigator={navigator}
              navigate={navigate}
              component={'ChatBroadcast'}
            />
            <SectionButton
              name={'Popup'}
              navigator={navigator}
              navigate={navigate}
              component={'NewsPopup'}
            />
            <SectionButton
              name={'Cupones'}
              navigator={navigator}
              navigate={navigate}
              component={'Coupons'}
            />
          </Menu>
          {locale == 'ar' ? (
            <GTM locale={locale} hidden={navigator != 'Lobbies'} />
          ) : (
            <div className='w-full mt-3 flex'>
              <Lobbies
                selectedLobby={selectedLobby}
                setSelectedLobby={setSelectedLobby}
                hidden={navigator != 'Lobbies'}
              />
            </div>
          )}
          {(navigator == 'StatisticsDemand' || navigator == 'Departments') && (
            <StatisticsDemand navigate={navigate} navigator={navigator} />
          )}
          {navigator == 'StatisticsSupply' && (
            <StatisticsSupply navigate={navigate} navigator={navigator} />
          )}
          {navigator == 'Departments' && <DepartmentTable navigate={navigate} />}
          {navigator == 'UserSearch' && (
            <UserSearch navigate={navigate} defaultId={params.get('user_id')} />
          )}
          {navigator == 'DriverPayments' && <DriverPayments navigate={navigate} locale={locale} />}
          {navigator == 'ChatBroadcast' && <ChatBroadcast navigate={navigate} />}
          {navigator == 'NewsPopup' && <NewsPopup navigate={navigate} />}
          {navigator == 'Coupons' && <Coupons navigate={navigate} />}
        </>
      )}
      {messages && <Messages visible={messages} setVisible={setMessages} locale={locale} />}
    </ThemeProvider>
  )
}

const StatisticsButton = ({ navigator, navigate, component, name }) => (
  <div
    className=' bg-yellow-500 w-32 h-10 rounded-lg 
flex justify-center items-center cursor-pointer'
    onClick={() => {
      if (navigator == component || navigator == 'Departments') {
        navigate('Lobbies')
      } else {
        navigate(component)
      }
    }}
  >
    {navigator == component || navigator == 'Departments' ? 'Ocultar' : name}
  </div>
)

const SectionButton = ({ navigator, navigate, component, name }) => (
  <div
    className='bg-blue-500 h-10 w-36 rounded-lg flex justify-center items-center cursor-pointer text-sm'
    onClick={() => {
      if (navigator == component) {
        navigate('Lobbies')
      } else {
        navigate(component)
      }
    }}
  >
    {navigator == component ? 'Ocultar' : name}
  </div>
)

export default App

const getLocale = () => {
  const path = window.location.pathname
  const segments = path.split('/')

  const locale = segments[1]

  return { locale }
}
