import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import Alert from '../Alert'
import { TextField } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export default ({ userId, type, lobby }) => {
  const [wppMessages, setWppMessages] = useState(false)
  const { loading, data } = useFetch(`${urlApi}/admin/users/id/${userId}`)

  const [createdTime, setCreatedTime] = useState('')
  const [createdDate, setCreatedDate] = useState('')
  const [lastTime, setLastTime] = useState('')
  const [lastDate, setLastDate] = useState('')

  useEffect(() => {
    if (!loading) {
      const createdDateTime = new Date(new Date(data.created_at).getTime() - 3 * 60 * 60 * 1000)
      setCreatedTime(
        createdDateTime.toISOString().substr(createdDateTime.toISOString().indexOf('T') + 1, 5)
      )
      setCreatedDate(
        dias[createdDateTime.getDay()] +
          ' ' +
          createdDateTime.getDate() +
          ' de ' +
          meses[createdDateTime.getMonth()]
      )
      const lastDateTimeInUruguay = new Date(
        new Date(data.last_login).getTime() - 3 * 60 * 60 * 1000
      ).toISOString()
      setLastTime(lastDateTimeInUruguay.substr(lastDateTimeInUruguay.indexOf('T') + 1, 5))
      setLastDate(
        dias[new Date(data.last_login).getDay()] +
          ' ' +
          new Date(data.last_login).getDate() +
          ' de ' +
          meses[new Date(data.last_login).getMonth()]
      )
      //trim user first name
      data.user_metadata.first_name = data.user_metadata.first_name.trim()
      if (data.user_metadata.phone_number.charAt(0) != '+') {
        data.user_metadata.phone_number =
          '+598' +
          data.user_metadata.phone_number.substring(1, data.user_metadata.phone_number.length)
      }
    }
  }, [loading])
  //Calculate age with data.user_metadata.birthdate
  const age = () => {
    const birthdate = new Date(data.user_metadata.birthdate)
    const today = new Date()
    let age = today.getFullYear() - birthdate.getFullYear()
    const month = today.getMonth() - birthdate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
      age--
    }
    return age
  }
  return (
    <div className='h-ful w-full'>
      {loading ? (
        <>Cargando...</>
      ) : (
        <>
          <div className='h-ful w-full'>
            <div className='flex flex-col justify-center mb-3'>
              <div className='flex justify-center'>
                <img
                  className='rounded-full mt-3 max-w-[95px] max-h-[95px]'
                  src={data.picture}
                  width='95px'
                  height='95px'
                />
              </div>
              {data.user_metadata.gender != 'Hombre' && data.user_metadata.gender != 'Mujer' && (
                <div className='flex m-auto text-2xl my-2 text-purple-900'>
                  <p className='font-bold mr-1'>GENERO: {data.user_metadata.gender}</p>
                </div>
              )}
              <p
                onClick={() => {
                  window.open(
                    `${window.location.origin}?navigation=UserSearch&user_id=${userId}`,
                    '_blank'
                  )
                }}
                className='text-base text-center font-bold text-light_coral cursor-pointer my-2'
              >
                Abrir perfil completo
              </p>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Nombre:</p> {data.user_metadata.first_name}
              </div>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Apellido:</p> {data.user_metadata.last_name}
              </div>
              <div clasName='flex m-auto'>
                <p className='font-bold mr-1'>
                  Tel:{' '}
                  <a
                    className='text-blue-600 cursor-pointer'
                    onClick={() => {
                      if (lobby) {
                        setWppMessages(true)
                      } else {
                        window.open(
                          `https://api.whatsapp.com/send/?phone=${data.user_metadata.phone_number}&text=%20`
                        )
                      }
                    }}
                  >
                    {data.user_metadata.phone_number}
                  </a>
                  <WhatsAppIcon
                    className='cursor-pointer text-green-600'
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send/?phone=${data.user_metadata.phone_number}&text=%20`
                      )
                    }
                  />
                </p>
              </div>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Email: </p> {data.email}
              </div>
              {data?.user_metadata?.preferences && (
                <div className='flex m-auto'>
                  <p className='font-bold mr-1'>Preferencias:</p>{' '}
                  {data.user_metadata.preferences.map(x => x + ' | ')}
                </div>
              )}
              {data?.user_metadata?.biography && (
                <div className='w-full flex justify-center'>
                  <div className='w-[400px] flex m-auto'>
                    <p className='font-bold'>Biografía:</p>
                    {data.user_metadata.biography}
                  </div>
                </div>
              )}
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Edad: </p>
                {age()}
              </div>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Registrado:</p> {createdDate} {createdTime}
              </div>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>Ultima vez:</p> {lastDate} {lastTime}
              </div>
              <div className='flex m-auto'>
                <p className='font-bold mr-1'>ID:</p> {data.user_id}
              </div>
            </div>
          </div>
          <Alert visible={wppMessages} onClose={() => setWppMessages(false)}>
            {type == 'owner' ? (
              <OwnerPressets userData={data} lobby={lobby} />
            ) : type == 'companion' ? (
              <CompanionPressets lobby={lobby} userData={data} />
            ) : (
              <LobbyCompanionPressets lobby={lobby} userData={data} />
            )}
          </Alert>
        </>
      )}
    </div>
  )
}

const OwnerPressets = ({ userData, lobby }) => {
  const departureDate = new Date(lobby.Journey.departure_time)
  let formattedDate =
    'el ' +
    dias[departureDate.getDay()] +
    ' ' +
    departureDate.getDate() +
    ' de ' +
    meses[departureDate.getMonth()]
  if (
    departureDate.getDate() == new Date().getDate() &&
    departureDate.getMonth() == new Date().getMonth()
  ) {
    formattedDate = 'hoy'
  } else if (
    departureDate.getDate() == new Date().getDate() - 1 &&
    departureDate.getMonth() == new Date().getMonth()
  ) {
    formattedDate = 'ayer'
  }
  const formattedCompanions = lobby.companions.map(x => x.first_name).join(', ')
  const shortedId = lobby._id.slice(-5)
  return (
    <div className='h-full'>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() =>
          window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
        &text=Hola%20${userData.user_metadata.first_name}%2C%20soy%20Natalia%20de%20soporte%20de%20Viatik.%0A%0AQuer%C3%ADa%20confirmar%20si%20${formattedDate}%20viajaste%20con%20${formattedCompanions}.%20Id%20de%20Viaje:%20${shortedId}%0A%0ARespondernos%20no%20te%20generar%C3%A1%20ning%C3%BAn%20gasto%20extra%2C%20simplemente%20es%20para%20estar%20al%20tanto%20y%20mejorar%20tu%20perfil.%0A%0AEspero%20tu%20respuesta...
      `)
        }
      >
        Hola {userData.user_metadata.first_name}, soy Natalia de soporte de Viatik.
        <br />
        Quería confirmar si {formattedDate} viajaste con {formattedCompanions}. Id de viaje{' '}
        {shortedId}
        <br />
        <br />
        Respondernos no generará ningún gasto extra, simplemente es para estar al tanto y mejorar tu
        perfil.
        <br />
        <br />
        Espero tu respuesta...
      </div>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() =>
          window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
        &text=Hola%20${
          userData.user_metadata.first_name
        }%2C%20soy%20Natalia%20de%20soporte%20de%20Viatik.%0A%0ATen%C3%A9s%20una%20reserva%20confirmada%20en%20tu%20viaje%20(Id%3A%20${shortedId})%20desde%20${encodeURIComponent(
            lobby.Journey.origin_name.locality
          )}%20hacia%20${encodeURIComponent(
            lobby.Journey.destination_name.locality
          )}%20para%20${formattedDate}.%0A%0ATe%20sugerimos%20coordinar%20los%20detalles%20con%20tu%20acompa%C3%B1ante%20para%20asegurarse%20de%20que%20todo%20est%C3%A9%20en%20orden.%0A%0ACualquier%20duda%2C%20estoy%20a%20disposici%C3%B3n.%20%C2%A1Buen%20viaje%21%20%F0%9F%9A%97
      `)
        }
      >
        Hola {userData.user_metadata.first_name}, soy Natalia de soporte de Viatik.
        <br />
        <br />
        Tenés una reserva confirmada en tu viaje (Id: {shortedId}) desde{' '}
        {lobby.Journey.origin_name.locality} hacia {lobby.Journey.destination_name.locality} para{' '}
        {formattedDate}.
        <br />
        <br />
        Te sugerimos coordinar los detalles con tu acompañante para asegurarse de que todo esté en
        orden.
        <br />
        <br />
        Cualquier duda, estoy a disposición. ¡Buen viaje! 🚗
      </div>
    </div>
  )
}

const CompanionPressets = ({ userData, lobby }) => {
  const [lobbyId, setLobbyId] = useState('')
  const [proposedLobby, setProposedLobby] = useState(false)
  return (
    <div className='h-full mt-40'>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() =>
          window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0ATu+solicitud+de+viaje+fue+ingresada+correctamente.+%0D%0AEn+caso+de+conseguirte+un+viaje+te+avisamos.`)
        }
      >
        Hola {userData.user_metadata.first_name},
        <br />
        Tu solicitud de viaje fue ingresada correctamente. En caso de conseguirte un viaje te
        avisamos 😃
      </div>
      {!proposedLobby ? (
        <div className='flex justify-center bg-gray-400 rounded-xl shadow-xl p-5 mb-4 text-left gap-3'>
          <h2 className='flex flex-col justify-center'>ID de lobby: </h2>
          <TextField size='small' label='Lobby ID' onChange={evt => setLobbyId(evt.target.value)} />
          <button
            className='px-4 bg-gray-500 shadow-xl rounded-xl'
            onClick={() => setProposedLobby(true)}
          >
            Proponer lobby
          </button>
        </div>
      ) : (
        <ProposeLobby userData={userData} lobbyId={lobbyId} />
      )}
    </div>
  )
}

const ProposeLobby = ({ userData, lobbyId }) => {
  const [formattedDate, setFormattedDate] = useState('')
  const [formattedTime, setFormattedTime] = useState('')
  const { loading, data } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)

  useEffect(() => {
    if (!loading) {
      const departureDate = new Date(data.Journey.departure_time)
      setFormattedDate(
        'el ' +
          dias[departureDate.getDay()] +
          ' ' +
          departureDate.getDate() +
          ' de ' +
          meses[departureDate.getMonth()]
      )
      if (
        departureDate.getDate() == new Date().getDate() &&
        departureDate.getMonth() == new Date().getMonth()
      ) {
        setFormattedDate('hoy')
      } else if (
        departureDate.getDate() == new Date().getDate() + 1 &&
        departureDate.getMonth() == new Date().getMonth()
      ) {
        setFormattedDate('mañana')
      }
      setFormattedTime(
        data.Journey.departure_time.substr(data.Journey.departure_time.indexOf('T') + 1, 5)
      )
      const searchDataString = JSON.stringify({
        origin: {
          lat: data.Journey.origin.coordinates[0],
          lng: data.Journey.origin.coordinates[1]
        },
        destination: {
          lat: data.Journey.destination.coordinates[0],
          lng: data.Journey.destination.coordinates[1]
        },
        originName: data.Journey.origin_name,
        destinationName: data.Journey.destination_name,
        selectedDate: data.Journey.departure_time,
        data: 1,
        seats: 1
      })
    }
  }, [loading])

  return (
    <>
      {loading ? (
        <>Cargando...</>
      ) : (
        <div
          className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
          onClick={() =>
            window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
                &text=Hola+${userData.user_metadata.first_name}%2C%0D%0AHay+un+conductor+que+viaja+de+${data.Journey.origin_name.locality}+a+${data.Journey.destination_name.locality}%2C+${formattedDate}+a+las+${formattedTime}.+El+costo+del+viaje+es+$${data.price}.%0D%0APodés+reservar+este+viaje+en+nuestra+App+o+ingresando+a+este+link:+https://app.viatik.com/join/${lobbyId}?utm_source=admin&utm_medium=whatsapp&utm_campaign=matches`)
          }
        >
          Hola {userData.user_metadata.first_name},
          <br />
          Hay un conductor que viaja desde {data.Journey.origin_name.locality} a{' '}
          {data.Journey.destination_name.locality}, {formattedDate} a las {formattedTime}. El costo
          del viaje es ${data.price}.
          <br />
          Podés reservar este viaje en nuestra App o ingresando a este link:
          https://app.viatik.com/join/{lobbyId}
          ?utm_source=admin&utm_medium=whatsapp&utm_campaign=matches
        </div>
      )}
    </>
  )
}

const LobbyCompanionPressets = ({ userData, lobby }) => {
  const departureDate = new Date(lobby.Journey.departure_time)
  let formattedDate =
    'el ' +
    dias[departureDate.getDay()] +
    ' ' +
    departureDate.getDate() +
    ' de ' +
    meses[departureDate.getMonth()]
  let formattedTime = lobby.Journey.departure_time.substr(
    lobby.Journey.departure_time.indexOf('T') + 1,
    5
  )
  if (
    departureDate.getDate() == new Date().getDate() &&
    departureDate.getMonth() == new Date().getMonth()
  ) {
    formattedDate = 'hoy'
  } else if (
    departureDate.getDate() == new Date().getDate() + 1 &&
    departureDate.getMonth() == new Date().getMonth()
  ) {
    formattedDate = 'mañana'
  }
  const shortedId = lobby._id.slice(-5)
  const companion = lobby.companions.find(x => x.user_id == userData.user_id)

  return (
    <div className='h-full'>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() =>
          window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
      &text=Hola%20${userData.user_metadata.first_name}%2C%20soy%20Natalia%20de%20soporte%20de%20Viatik.%0A%0AQuer%C3%ADa%20confirmar%20si%20${formattedDate}%20viajaste%20con%20el%20conductor%20${lobby.Owner_info.first_name}.%20Id%20de%20Viaje:%20${shortedId}%0A%0ARespondernos%20no%20te%20generar%C3%A1%20ning%C3%BAn%20gasto%20extra%2C%20simplemente%20es%20para%20estar%20al%20tanto%20y%20mejorar%20tu%20perfil.%0A%0AEspero%20tu%20respuesta...
    `)
        }
      >
        Hola {userData.user_metadata.first_name}, soy Natalia de soporte de Viatik.
        <br />
        Quería confirmar si {formattedDate} viajaste con el conductor {lobby.Owner_info.first_name}.
        Id de viaje: {shortedId}
        <br />
        <br />
        Respondernos no generará ningún gasto extra, simplemente es para estar al tanto y mejorar tu
        perfil.
        <br />
        <br />
        Espero tu respuesta...
      </div>
      <div
        className='bg-gray-400 rounded-xl shadow-xl cursor-pointer p-5 mb-4 text-left'
        onClick={() =>
          window.open(`https://api.whatsapp.com/send/?phone=${userData.user_metadata.phone_number}
    &text=Hola%20${
      userData.user_metadata.first_name
    }%2C%20soy%20Natalia%20de%20Viatik.%0A%0ATen%C3%A9s%20una%20reserva%20confirmada%20en%20el%20viaje%20(Id%3A%20${shortedId})%20con%20${
            lobby.Owner_info.first_name
          }%20desde%20${encodeURIComponent(companion.start_name)}%20hacia%20${encodeURIComponent(
            companion.end_name
          )}%20para%20${formattedDate}.%0A%0ATe%20sugerimos%20coordinar%20los%20detalles%20con%20el%20conductor%20para%20asegurarte%20de%20que%20todo%20est%C3%A9%20en%20orden.%0A%0ACualquier%20duda%2C%20estoy%20a%20disposici%C3%B3n.%20%C2%A1Buen%20viaje!%20%F0%9F%9A%97
  `)
        }
      >
        Hola {userData.user_metadata.first_name}, soy Natalia de Viatik.
        <br />
        <br />
        Tenés una reserva confirmada en el viaje (Id: {shortedId}) con {lobby.Owner_info.first_name}{' '}
        desde {companion.start_name} hacia {companion.end_name} para {formattedDate}.
        <br />
        <br />
        Te sugerimos coordinar los detalles con el conductor para asegurarte de que todo esté en
        orden.
        <br />
        <br />
        Cualquier duda, estoy a disposición. ¡Buen viaje! 🚗
      </div>
    </div>
  )
}
