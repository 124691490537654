import Button from '@mui/material/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const NewButton = ({ type, id, name, onClick, style, className, disabled }) => {
  return (
    <Button
      name={id}
      type={type}
      variant='contained'
      className={className}
      style={{
        fontSize: '20px',
        backgroundColor: disabled ? '#9ca3af' : '#d65944',
        color: 'white',
        borderRadius: '30px',
        textTransform: 'none',
        paddingLeft: '20%',
        paddingRight: '20%',
        ...style
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </Button>
  )
}

export default NewButton
